.hidden-navbar{
   display: none;
  
}
.children{
    height: 95vh;
    overflow: auto;
    /* width: 100%; */
}
.col-lg-2{
    background-color: black;
    border-bottom: 1px solid lightgray;
    max-width: 250px;
}