.custom-row-payment {
    border: 1px solid #dbdada;
    background-color: white;
    border-right: 1px solid white;
    height: auto; /* Adjust the shadow properties as needed */
  
  }
  .custom-row-custom-details {
    
    background-color: white;
    display: flex;
    align-items: center;
    height: 45px; /* Adjust the shadow properties as needed */
  
  }