.border-left {
  border-left: 1px solid black;
}
.border-right {
  border-right: 1px solid black;
}
.border-bottom-light {
  border-bottom: 1px solid  black !important;
}
.border-bottom {
  border-bottom: 1px solid  black !important;
  margin-bottom: 8px;
}
.border-top-bottom {
  border-top: 1px solid  black !important;
  border-bottom: 1px solid  black !important;
}
.border-top {
  border-top: 1px solid black;
}
.border {
  border: 1px solid black;
}
.border-black {
  border-right: 1px solid black;
}
.table-border-head{
  border-top: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  border-bottom: 1px solid #000000 !important;
}
.table-border-last{
  border-top: 1px solid #000000 !important;
  border-bottom: 1px solid #000000 !important;
}
.border-double {
    border-right: 1px solid black;
    text-align: right;
  }
.border-tax{
  border-right: 1px solid black;
  border-bottom: 1px solid #000000 !important;
}
.border-roundoff{
  border-right: 1px solid black;
  border-bottom: 1px solid #000000 !important;
}
.border-for-received{
  border-right: 1px solid #000000 !important;
  border-bottom: 1px solid #000000 !important;
}
.custom-thead {
    background-color: white !important;
}
.total-row{
  background-color: #F8F9FA;
}
.border-col{
  border-right:1px solid  black;
  border-left:1px solid  black;
}
.author-col{
  display: flex;
  flex-direction: column;
  align-items: center;
}