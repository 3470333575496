@media (max-width: 1200px) {
    .apexcharts-canvas {
      width: 300 !important;
      height: 300 !important;
    }
  }
  
  @media (max-width: 768px) {
    .apexcharts-canvas {
      width: 250 !important;
      height: 250 !important;
    }
  }

  .bg-gradient-to-l {
    background-image: linear-gradient(to bottom,#f1f0fc, #ffffff);
    /* box-shadow: 
    0px 2px 15px -3px rgba(0,0,0,0.1),
    0px 10px 15px -3px rgba(0,0,0,0.21),
    0px 10px 15px -3px rgba(0,0,0,0.041); */
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 10px; */
  }  

  .font-heading{
    font-size: 14px !important;
    font-weight: 700 !important;
  }
  .font-body{
    font-size: 14px !important;
    font-weight: 400 !important;
  }

  .sales-total-card{
    background-color:#e3f0ff31 !important;
    border-radius:8px 8px 8px 8px!important;
    border: 1px solid #b4d7ff;
  }
  .profit-card{
    background-color:rgba(234, 243, 255, 0.5) !important;
    border-radius:8px !important;
    border: 1px solid rgb(152, 187, 170) !important;
    }
    
  .card-text{
    font-size: 14px !important;
    font-weight: 700 !important;
  }
  .card-value{
    font-size: 14px !important;
    font-weight:400 !important;
  }

  .container{
    padding: 25px;
    background: radial-gradient(178.94% 106.41% at 26.42% 106.41%, #FFF7B1 0%, rgba(255, 255, 255, 0) 71.88%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, #FFFFFF;
    border-radius: 23px;
    transition: all 0.8s cubic-bezier(0.15, 0.83, 0.66, 1);
    cursor: pointer;
  }

  .no_padding {
    padding: 0px;
  }
  @keyframes metrics_animation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.metrics {
    animation-name: metrics_animation;
    animation-duration: 0.5s;
    transition: ease-in-out;
}
