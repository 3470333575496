.title-label {
  font-size: 14px !important;
  font-weight: 600 !important;
}
.title_value {
  font-size: 14px !important;
  color: #76797c !important;
  font-weight: 400;
}
.price_section {
  width: 370px;
}
.instock{
  font-size:14px;
  background-color:#e8f6ef;
  color:green;
  border-radius: 5px;
  width:100px;
  text-align: center;
  padding: 4px;
}

.outofstock{
  font-size:14px;
  background-color:#FBECEC;
  color:red;
  border-radius: 5px;
  width:110px;
  text-align: center;
  padding: 4px;
}

.tableheading{
  padding:10px;
  font-size: 14px;
  font-weight: 700;
}

.font-heading{
  font-size: 14px !important;
  font-weight: 600 !important;
}

.card-border{
  border-top: 1px solid rgba(3, 19, 1, 0.137) !important;
  border-bottom: 1px solid rgba(3, 19, 1, 0.137) !important;
  border-right: 1px solid rgba(3, 19, 1, 0.137) !important;
  border-left: 1px solid rgba(3, 19, 1, 0.137) !important;
}

.summary-border{
  border-top: 5px solid #DAD8A7  !important;
  border-bottom: 2px solid rgba(3, 19, 1, 0.137) !important;
  border-right: 1px solid rgba(3, 19, 1, 0.137) !important;
  border-left: 1px solid rgba(3, 19, 1, 0.137) !important;
}
/* .sales-total-card{
  background-color:rgb(233, 183, 192,0.1) !important;
  border-radius:8px !important;
  border: 1px solid #e9b7c0;
}
.profit-card{
background-color: rgba(232, 246, 239, 0.1) !important;
border-radius:8px !important;
border: 1px solid #395455 !important;
} */

.card-text{
font-size: 14px !important;
font-weight: 800 !important;
}
.card-value{
font-size: 14px !important;
font-weight: 500 !important;
}