.custom-col {
    border: 1px solid blue;
    height: 79px;
   width: 10px;
    background-color: #d5deeb;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
  }
  .point-cursor {
    cursor: pointer;
  }
  
  .custom-col-1 {
    border: 1px solid rgb(192, 192, 253);
    height: 79px;
   
    background-color: #f0f2f5;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
  }
  

  .custom-col-2 {
    border: 1px solid rgb(192, 192, 253);
    height: 79px;
   
    background-color: #f0f2f5;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
  }


  .custom-row-2 {
    border: 1px solid #dbdada;
    background-color: white; /* Adjust the shadow properties as needed */
  
  }
  .custom-row-1 {
    border: 1px solid #dbdada;
    background-color: white;
    border-right: 1px solid white;
    height: 170px; /* Adjust the shadow properties as needed */
  
  }
  .custom-row-payment {
    border: 1px solid #dbdada;
    background-color: white;
    border-right: 1px solid white;
    height: auto; /* Adjust the shadow properties as needed */
    
  
  }
  .custom-row-custom-details {
    
    background-color: white;
    display: flex;
    align-items: center;
    height: 45px; /* Adjust the shadow properties as needed */
  
  }
  .custom-row-invoice {
    border: 1px solid #dbdada;
    background-color: white;
    border-right: 1px solid white;
    height: 300px; /* Adjust the shadow properties as needed */
  
  }
  .custom-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
  }
  .horizontal-line {
    border-top: 1px solid #ccc; /* Adjust the color and thickness as needed */
    margin: 1px 0; /* Adjust the margin as needed */
  }
  .image-box {
    /* width: 175px;
    height: 74px;
    border: 1px dashed rgb(165, 158, 158)#333;
    position: relative;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 10px; */
  }
  .image-box-2 {
   
    height: 50px;
    border: 1px dashed rgb(165, 158, 158)#333;
    position: relative;
    background-color: white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
  }
  .image-box-3 {
    width: 200px;
    height: 50px;
    border: 2px dashed rgb(165, 158, 158)#333;
    position: relative;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
    
  }
  
  .second-row {
    background-color: white;
    padding-top: 20px;
   
    border: 1px solid #dbdada;
    border-top: white;

  }
  .total {
    height: 45px;
    background-color: #e2dfdf ;
    border: 1px solid #dbdada;
    border-top: 0px white;
    border-bottom: 0px white;

   
  }
  .vertical-line-total {
    border-left: 1px solid #333; /* Adjust the color and thickness as needed */
    height: auto; /* Adjust the height of the vertical line */
  }
  .bottom-grid {
    background-color: white; 
    border: 1px solid #dbdada;/* Adjust the height of the vertical line */
   
  }
  .bottom-grid-col-1{
    border: 0px solid #dbdada;
    height: 200px;/* Adjust the height of the vertical line */
   
  }
  .bottom-grid-col-2{
    border: 0px solid dbdada;
    border-left: 1px solid #dbdada;
    
    /* Adjust the height of the vertical line */
   
  }
  .terms-conditions{
   background-color: #e9e7e7;
  }
  .ship-to {
  width: 50%;
    height: 170px;
    border-right: 1px solid  #dbdada;
    
  
  }
  .error-highlight {
    border: 1px solid red;  
  }


  .info-section {
    display: flex;
    margin-bottom: 7px;
  }
  
  .info-label {
    font-weight: 600;
  }
  
  .info-value {
    font-weight: 400;
    color: #343a40;
    margin-left:10px;
    text-align: left;
  }

  .text-black-50 {
    color: rgba(0, 0, 0, 0.5);
  }