.sale-link {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: #333;
  border: 1px solid #1a237e;
  transition: background-color 0.3s ease;
  border-radius: 5px;
}

.sale-link:hover {
  background-color: #f4f4f4;
}

.sale-link:focus {
  outline: none;
}

.address-card {
  max-height: 130px;
  overflow: scroll;
}

.balance-total {
  font-size: 20px;
  font-weight: 700;
  color: steelblue;
  vertical-align: bottom;
}
.tax-total {
  font-size: 20px;
  font-weight: 700;
  color: #45474B;
  vertical-align: bottom;
}
.balance-amt-txt {
  font-size: 35px;
  font-weight: 700;
  color: #1a237e;
}

.grand-total-block {
  font-size: 24px;
  font-weight: 700;
  color: green;
  vertical-align: bottom;
}

.grand-total-txt {
  padding: 20px 0px 20px 0px;
  font-size: 40px;
  font-weight: 700;
  color: green;
}

.total-amount-block {
  padding: 6px;
}

.grand-total-block {
  padding: 0px;
  border-bottom: 1px solid #dee2e6;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.balance-block {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.total-label {
  font-weight: bold;
  font-size: 24px; 
  color: #28a745; 
}
.paid-label {
  font-weight: 600;
  font-size: 18px; 
  color: #4682B4;
}
.bal-label {
  font-weight: 600;
  font-size: 18px; 
  color: #1a237e;
}
.grand-total-text {
  font-size: 40px;
  font-weight: 700; 
  color: #28a745; 
  display: flex;
  align-items: center;
}

.grand-paid-text {
  font-size: 30px;
  font-weight: 700; 
  color: #4682B4; 
  display: flex;
  align-items: center;
}

.grand-bal-text {
  font-size: 30px;
  font-weight: 700; 
  color: #1a237e; 
  display: flex;
  align-items: center;
}

#tooltip-overglance> .tooltip-inner {
  background-color: #fff;
  color: #000;
  border: 1px solid lightgrey;
}
.t_and_c {
  max-height: 150px;
  overflow: scroll;
}
