html {
  font-size: 14px;
} 

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  background-color: #f6f8fa;
}
